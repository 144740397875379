import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import content from '../data/content';

const ReasonsMap = content.reasons.map((reasons, index) => {
    return (
        <Grid item key={index} xs={12} sm={6} md={4}>
            <Card
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <CardMedia
                    component='img'
                    image={reasons.image}
                    alt={reasons.alt}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant='h5' component='h2'>
                        {reasons.title}
                    </Typography>
                    <Typography>{reasons.content}</Typography>
                    <Typography variant='h8'>
                        Foto: {reasons.imageCredit}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    );
});

export default ReasonsMap;
