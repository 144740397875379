import React from 'react';

const Insights = () => {
    return (
        <div>
            <h1>insights</h1>
        </div>
    );
};

export default Insights;
