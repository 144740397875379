import { Container, Grid, Typography } from '@mui/material';
import ReasonsMap from '../components/ReasonsMap';
import content from '../data/content';

import '../App.css';

const HomePage = () => {
    return (
        <div
            className='background'
            style={{
                backgroundImage: `url(${content.top.backgroundImage})`,
                backgroundSize: 'cover',
            }}
        >
            <section id='top'>
                <Typography
                    variant='h2'
                    component='h1'
                    align='center'
                    paddingTop
                >
                    {content.top.title}
                </Typography>
            </section>
            <Container sx={{ py: 8 }} maxWidth='md' id='reasons'>
                <Grid container spacing={4}>
                    {ReasonsMap}
                </Grid>
            </Container>
            <Typography
                variant='h6'
                style={{ bottom: '0', right: '0', position: 'fixed' }}
            >
                Foto: {content.top.imageCredit}
            </Typography>
        </div>
    );
};

export default HomePage;
