const content = {
    top: {
        title: 'Hvorfor flytte til Hamar?',
        backgroundImage: './images/Stortorget.jpg',
        imageCredit: 'Bjørnar Fjeldberg',
    },
    reasons: [
        {
            title: 'Norges dyreste stupetårn.',
            content:
                'Stupetårnet på Hamar står på Koigen i Hamar, ved bredden av innsjøen Mjøsa. Byggingen av stupetårnet er blitt betegnet som en skandale på grunn av summen det kostet og tiden det tok å bygge det. Tårnet skulle opprinnelig koste rundt 1,5 millioner, men den endelige prisen ble 25,8 millioner kroner. ',
            image: './images/Koigen-i-Hamar.jpg',
            alt: 'Koigen stupetårn',
            imageCredit: 'Jenny Ann Magnusson',
        },
        {
            title: 'Domkirkeodden',
            content:
                'Kåret til et av verdens vakreste museer! Opplev middelalderens Hamar, friluftsmuseum, utstillinger, berømt arkitektur, urtehage mm.',
            image: './images/Kveld-ved-Hamardomen.jpg',
            alt: 'Kveld ved Domkirkeodden',
            imageCredit: 'Foto Ingse Kjernmoen',
        },
        {
            title: 'Hedmarksvidda',
            content:
                'Vakre Hedmarksvidda inviterer til magiske vinteropplevelser. Nyt langrennslandskapet. Hedmarksvidda byr på hele 500 km med løyper, fra Sjusjøen i nord til Løten i sør. Skøyte på 110 km Lengde lyssti på 11 km Høyeste punkt er Brumundkampen på 827 moh',
            alt: 'Mann som går på ski over Hedmarksvidda',
            image: './images/Hedmarksvidda.jpg',
            imageCredit: 'Odd Lindstad',
        },
    ],
};
export default content;
