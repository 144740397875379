import { Button, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import HomePage from './pages/HomePage';

function App() {
    const [auth, setAuth] = useState(false);
    const [inputHandle, setInputHandle] = useState('');
    const [loginError, setLoginError] = useState('');
    const handleInputChange = (event) => {
        setInputHandle(event.target.value);
    };
    const loginHandle = (password) => {
        if (password === 'Hamar2022') {
            setAuth(true);
        } else {
            setLoginError('feil passord');
        }
    };

    return (
        <div className='App'>
            {!auth && (
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box sx={{ mt: 1 }}>
                        <TextField
                            margin='normal'
                            name='password'
                            label='Password'
                            type='password'
                            id='password'
                            fullWidth
                            onChange={handleInputChange}
                        />
                        <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            onClick={() => loginHandle(inputHandle)}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Login
                        </Button>
                        <p>{loginError}</p>
                    </Box>
                </Box>
            )}
            {auth && <HomePage />}
        </div>
    );
}

export default App;
